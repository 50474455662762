if (!Object.fromEntries) {
  Object.fromEntries = function (iterable) {
    var obj = {};
    iterable.forEach(function (entry) {
      if (Array.isArray(entry) && entry.length === 2) {
        obj[entry[0]] = entry[1];
      } else {
        throw new TypeError('Invalid iterable passed to fromEntries');
      }
    });
    return obj;
  };
}
